import './App.css';
import {
  BrowserRouter as Router,
  Route, Routes,
} from "react-router-dom";
import AuthPage from './Components/AuthPage/AuthPage';
import CollectorPage from './Components/CollectorPage/CollectorPage';
import LinkPage from './Components/LinkPage/LinkPage';
import axios from "axios";
import Settings from './Components/Settings/Settings';

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            <Route exact path='/' element={<AuthPage />}></Route>
            <Route exact path='/link_page' element={<LinkPage />}></Route>
            <Route exact path='/data_collection' element={<CollectorPage />}></Route>
            <Route exact path='/settings' element={<Settings />}></Route>
            <Route exact path='/404' element={<div />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
