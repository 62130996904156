import styles from './AuthPage.module.css'
import { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { addressStore } from "../Store/ConfigStore";
import { useStore } from "effector-react";
import axios from "axios";



function AuthPage() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const address = useStore(addressStore);

    let navigate = useNavigate();
    const auth = () => {
        if (login.trim() !== '') {
            if (password.trim() !== '') {
                const request = {
                    "username": login.trim(),
                    "password": password.trim()
                };
                axios.post(address + `mod/auth`, request)
                    .then(res => {
                        if (res.status === 200) {
                            sessionStorage.setItem("token", res["data"]["Access"])
                            // alert("Успешная авторизация")
                            navigate('/link_page')
                        }
                    }).catch((error) => {
                        alert("Ошибка авторизации")
                        navigate('/link_page')
                    });
            } else {
                alert("Введите пожалуйста пароль")
            }
        } else {
            alert("Введите пожалуйста логин")
        }
    }

    const onKeyDown = e => {
        if (e.key === 'Enter')
            auth()
    }

    let fix = true
    useEffect(() => {
        if ((sessionStorage.getItem("token") !== "" && sessionStorage.getItem("token") !== null && sessionStorage.getItem("token") !== undefined)) {
            if (fix) {
                // alert("Если ты залогинился, то ты залогинен")
                navigate('/link_page')
            }
        }
        fix = !fix
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 500, alignItems: 'center' }}>
            <div className={styles.menuWrapper}>
                <div>
                    <div className={styles.headerText}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>Вход в приложение</div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}><input
                            value={login}
                            className={styles.inputWrapper}
                            onChange={(e) => setLogin(e.target.value)}
                            placeholder={"Введите логин..."}
                            onKeyDown={onKeyDown}
                        /></div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}><input
                            value={password}
                            type='password'
                            className={styles.inputWrapper}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={"Введите пароль..."}
                            onKeyDown={onKeyDown}
                        /></div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                            <div className={styles.buttonWrapper} onClick={() => auth()}>
                                <div >Войти</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AuthPage;
